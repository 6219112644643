import { backendPath } from "./constants"
import axios from "axios"


export const addShopNote = async (shopId, date, {categoryId, productId, value}) => 
    ((await axios({
        method: 'post',
        url: `${backendPath}/notes/`,
        data: {
            shop_id: shopId,
            info_date: date,
            category_id: categoryId,
            product_id: productId,
            value: value,
        }, 
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        } 
          
    }))?.data || [])
    