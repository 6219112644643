import {useCallback, useEffect, useRef, useState} from "react";
import {useTelegram} from "./hooks/useTelegram";
// import {getAllShops} from "./requests/getAllShops";
import {getShopDetailInfo} from "./requests/getShopDetailInfo";
import {getShopCalendar} from "./requests/getShopCalendar";
import {addShopNote} from "./requests/addShopNote";
// import { deleteShopNote } from "./requests/deleteShopNote";
import moment from "moment";

const shopId = '1'
const dateDayDiapason = 15
const halfOfDiapason = Math.floor(dateDayDiapason / 2)
const dateFormat = 'YYYY-MM-DD'

const prepareData = async (start, end) => {
  const newData = {}
  let currentDate = start.clone()
  while(currentDate.isSameOrBefore(end)){
    const strDate = currentDate.format(dateFormat)
    newData[strDate] = {
      date: strDate
    }
    currentDate = currentDate.add(1, 'days')
  }
  const shopCalendar = await getShopCalendar(shopId, start.format(dateFormat), end.format(dateFormat))
  for(const calendarInfo of shopCalendar) {
    newData[calendarInfo.date] = calendarInfo
  }
  return newData
}

function App() {
  const isInit = useRef(null)
  const {tg, user, queryId} = useTelegram();
  const today = moment()
  const [data, setData] = useState({})
  const [shopInfo, setShopInfo] = useState()
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()

  useEffect(() => {
    console.log('trey set tg.ready() ...', Boolean(tg))
    tg?.ready();
    console.log('trey set tg.ready() ... DONE')
    //дисейбл линт на зависимости т.к. нужен только один раз запуск
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if(!isInit.current){
      isInit.current = true;
      (async () => {
        const shopDetailInfo = await getShopDetailInfo(shopId)
        setShopInfo(shopDetailInfo)
        setMinDate(moment().subtract(halfOfDiapason, 'days'))
        setMaxDate(moment().add(halfOfDiapason, 'days'))
      })()



      // (async () => {
      //   const allShops = await getAllShops()
      //   console.log("🚀 ~ useEffect ~ allShops:", allShops)
        
      //   const shop = allShops[0]
        
      //   const shopDetailInfo = await getShopDetailInfo(shop.id)
      //   console.log("🚀 ~ useEffect ~ shopDetailInfo:", shopDetailInfo)
        
      //   const shopCalendar = await getShopCalendar(shop.id, '2024-09-10', '2024-09-30')
      //   console.log("🚀 ~ useEffect ~ shopCalendar:", shopCalendar)
  
      //   const shopNotes = await addShopNote(shop.id, '2024-09-08', {
      // categoryId: categories[0].id,
      //   productId: products[0].id,
      //   value: 54,
      // })
      //   console.log("🚀 ~ useEffect ~ addShopNote:", shopNotes)
  
      //   for( const shopNote of shopNotes.info) {
      //     if(shopNote?.id){
      //       const deleteShopNoteResult = await deleteShopNote(shopNote?.id, shop.id)
      //       console.log("🚀 ~ useEffect ~ deleteShopNoteResult:", deleteShopNoteResult)  
      //     }
      //     console.log("🚀 ~ useEffect ~ deleteShopNote error :", shopNote)  
      //   }
  
      // })()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addOnDate = useCallback((date) => {
    setData(prev => ({
      ...prev,
      [date]: {
        ...prev[date],
        isOnAddMode: true,
        addData: {
          categoryId: 1,
          productId: 1,
          value: undefined,
        }
      }
    }))
  }, [])

  const setAddInfo = useCallback(({date, addData}) => {
    setData(prev => ({
      ...prev,
      [date]: {
        ...prev[date],
        addData: {
          ...prev[date].addData,
          ...addData
        }
      }
    }))
  }, [])

  const cancelOnDate = useCallback((date, newDateNodes) => {
    setData(prev => ({
      ...prev,
      [date]: {
        ...prev[date],
        isOnAddMode: false,
        addData: undefined,
        info: newDateNodes
      }
    }))
  }, [])

  const saveOnDate = useCallback(async (date) => {
    const newDateNodes = await addShopNote(shopId, date, data[date].addData)
    cancelOnDate(date, newDateNodes.info)
  }, [cancelOnDate, data])

  useEffect(() => {
    (async () => {
      if(!minDate || !maxDate)
        return;
      const newData = await prepareData(minDate, maxDate)
      setData(prev => ({...newData, ...prev}))
    })()
      
  }, [minDate, maxDate])

  return (
    <div className="App" style={{padding: '10px'}}>

      <div style={{padding: '10px'}}>tg: {JSON.stringify(tg)}</div>
      <div style={{padding: '10px'}}>tg.initDataUnsafe: {JSON.stringify(tg?.initDataUnsafe)}</div>
      <div style={{padding: '10px'}}>user: {JSON.stringify(user)}</div>
      <div style={{padding: '10px'}}>queryId: {queryId}</div>
      <div style={{padding: '10px'}}>tg.WebApp.initData: {JSON.stringify(tg?.WebApp?.initData)}</div>

      <button onClick={() => {setMinDate(minDate.clone().subtract(dateDayDiapason, 'days'))}}>
        Загрузить предыдущие дни
      </button>
      {Object
        .values(data)
        .sort((a, b) => moment(a.date).isAfter(b.date) ? 1 : -1 )
        .map(({date, info, isOnAddMode, addData}) => {
          const isAddBtn = moment(date).isSameOrAfter(today.startOf("day"))
          return (
            <div key={date} style={{padding: '10px'}}>
              <div>{date}</div>
              {isAddBtn ? (isOnAddMode ? (
                <div style={{backgroundColor: "lightgrey", padding: '10px'}}>
                  <div>Категория: </div>
                  <select 
                    value={addData.categoryId}
                    onChange={(e) => {
                      setAddInfo({date, addData: {categoryId: e.target.value}})
                    }}
                  >
                    {(shopInfo?.categories || []).map(({id, name}) => (
                      <option value={id} key={id}>{name}</option>
                    ))}
                  </select>
                  
                  <div>Тип товара: </div>
                  <select 
                    value={addData.productId}
                    onChange={(e) => {
                      setAddInfo({date, addData: {productId: e.target.value}})
                    }}
                  >
                    {(shopInfo?.products || []).map(({id, name}) => (
                      <option value={id} key={id} >{name}</option>
                    ))}
                  </select>
                  {shopInfo?.categories.find(({id}) => `${id}` === addData.categoryId)?.is_require_value ? (
                    <>
                      <div>Значение: </div>
                      <input 
                        value={addData.value} 
                        type="number" 
                        step="1" 
                        onChange={(e) => {setAddInfo({date, addData: {value: e.target.value}})}}
                      />
                    </>
                  ) : null}
                  
                  <div style={{padding: '10px'}}>
                    <button onClick={() => {cancelOnDate(date)}}>Отменить</button>
                    <button onClick={() => {saveOnDate(date)}}>Сохранить</button>
                  </div>
                </div>
              ): <button onClick={() => {addOnDate(date)}}>+ Добавить</button>) : 'Нет данных'}
              <div>
                {
                  info?.map(({id, category_id, product_id, value}) => 
                    <div 
                      key={id} 
                      style={{
                        padding: '5px', 
                        margin: '5px', 
                        backgroundColor: 'lightslategrey',
                        borderRadius: '5px'
                      }}
                    >
                      {`${shopInfo.categories.reduce((s, {id, name}) => (id === category_id ? name : s),'_')}
                       | ${shopInfo.products.reduce((s, {id, name}) => (id === product_id ? name : s),'_')}
                       | ${value || '_'}`}
                    </div>
                  )
                }
              </div>

              <br/>
            </div>
          )
        }
      )}
      <button onClick={() => {setMaxDate(maxDate.clone().add(dateDayDiapason, 'days'))}}>
        Загрузить последующие дни
      </button>
    </div>
  );
}

export default App;
